.newArrival{
    background-color: #F2F3F7;
    .breadcrumb{
        font-size: 12px;
        text-align: left;
        line-height: 18px;
        color: #ABABAB;
        margin: 19px auto;
        padding-left: 30px;
    }
}